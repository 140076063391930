import React, {useEffect, useState} from 'react';
import './VerwaltungWettkampf.css';
import NavbarDisziplin from "../components/NavbarDisziplin";
import apiService from "../services/ApiService";
import ApiService from "../services/ApiService";

function VerwaltungWettkampf(props) {
    const [displayText, setDisplayText] = useState('');
    const [loading, setLoading] = useState(false);
    const [disziplinList, setDisziplinList] = useState([]);
    const [ligaList, setLigaList] = useState([]);
    const [mannschaftenList, setMannschaftenList] = useState([]);

    const [showGeneratedMatchups, setShowGeneratedMatchups] = useState(false);
    const [isSaveSuccessful, setIsSaveSuccessful] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [selectedLiga, setSelectedLiga] = useState();
    const [paarungen, setPaarungen] = useState([]);

    // Hilfsfunktion, um sicherzustellen, dass das Element in rule existiert
    const isExistingRuleElement = (rule, index) => {
        return index < rule.length ? true : false;
    };

    const generateSyncMatchups = (ligaId) => {
        setIsSaveSuccessful(false);
        setHasError(false);
        setPaarungen([]);
        setSelectedLiga(ligaId);
        const filteredMannschaften = mannschaftenList
            .filter((mannschaft) => mannschaft.liga !== null && mannschaft.liga.id === ligaId);
        const countOfMannschaften = filteredMannschaften.length;
        if (countOfMannschaften === 5 || countOfMannschaften === 6) {
            setShowGeneratedMatchups(true)
            const rules = [
                [4, 1, 5, 3, 2, 6],
                [1, 2, 3, 4, 5, 6],
                [1, 3, 2, 5, 4, 6],
                [5, 1, 4, 2, 3, 6],
                [4, 5, 2, 3, 1, 6]
            ];
            for (const rule of rules) {
                let newDurchgang = [];

                // Funktion, um "ohne Gegner" zu setzen, wenn keine entsprechende Mannschaft vorhanden ist
                const getMannschaftName = (index) => {
                    const mannschaft = filteredMannschaften[index - 1];//filteredMannschaften=man1,man2,man3,man4,man5
                    if (mannschaft) {
                        return mannschaft;
                    }
                    return "ohne Gegner";
                };
                newDurchgang.push([
                    getMannschaftName(rule[0]).id,
                    getMannschaftName(rule[1]).id
                ]);
                newDurchgang.push([
                    getMannschaftName(rule[2]).id,
                    getMannschaftName(rule[3]).id
                ]);
                if (countOfMannschaften === 5) {
                    newDurchgang.push([
                        getMannschaftName(rule[4]).id,
                        0
                    ]);
                } else {
                    newDurchgang.push([
                        getMannschaftName(rule[4]).id,
                        getMannschaftName(rule[5]).id
                    ]);
                }
                setPaarungen((prevPaarungen) => [...prevPaarungen, newDurchgang]);
            }
        }
        if (countOfMannschaften === 7) {
            setShowGeneratedMatchups(true)
            const rules = [
                      [4, 1, 5, 3, 2, 6],
                [7, 3, 1, 2, 4, 6, 5, 7],
                      [7, 1, 3, 2, 6, 5],
                [4, 2, 5, 1, 7, 4, 3, 6],
                      [3, 4, 2, 5, 7, 6],
                [1, 6, 1, 3, 4, 5, 2, 7]
            ];
            for (const rule of rules) {
                let newDurchgang = [];

                // Funktion, um "ohne Gegner" zu setzen, wenn keine entsprechende Mannschaft vorhanden ist
                const getMannschaftName = (index) => {
                    const mannschaft = filteredMannschaften[index - 1];//filteredMannschaften=man1,man2,man3,man4,man5
                    if (mannschaft) {
                        return mannschaft;
                    }
                    return "ohne Gegner";
                };
                newDurchgang.push([
                    getMannschaftName(rule[0]).id,
                    getMannschaftName(rule[1]).id
                ]);
                newDurchgang.push([
                    getMannschaftName(rule[2]).id,
                    getMannschaftName(rule[3]).id
                ]);
                newDurchgang.push([
                    getMannschaftName(rule[4]).id,
                    getMannschaftName(rule[5]).id
                ]);
                if (isExistingRuleElement(rule, 6)) {
                    newDurchgang.push([
                        getMannschaftName(rule[6]).id,
                        getMannschaftName(rule[7]).id
                    ]);
                }
                setPaarungen((prevPaarungen) => [...prevPaarungen, newDurchgang]);
            }
        }
    }
    const generateAsyncMatchups = (ligaId) => {
        setIsSaveSuccessful(false);
        setHasError(false);
        setPaarungen([]);
        setSelectedLiga(ligaId);
        const filteredMannschaften = mannschaftenList
            .filter((mannschaft) => mannschaft.liga !== null && mannschaft.liga.id === ligaId);
        const countOfMannschaften = filteredMannschaften.length;
        if (countOfMannschaften === 6) {
            setShowGeneratedMatchups(true)
            const rules = [
                [4, 1, 2, 6, 5, 3],
                [1, 2, 3, 4, 6, 5],
                [1, 3, 2, 5, 4, 6],
                [5, 1, 4, 2, 3, 7, 6, 7],
                [3, 6, 4, 5, 1, 7, 2, 7],
                [1, 6, 2, 3, 4, 7, 5, 7]
            ];
            for (const rule of rules) {
                console.log("in rule: " + rule);
                let newDurchgang = [];

                // Funktion, um "ohne Gegner" zu setzen, wenn keine entsprechende Mannschaft vorhanden ist
                const getMannschaftName = (index) => {
                    console.log("index: " + index);
                    const mannschaft = filteredMannschaften[index - 1];//filteredMannschaften=man1,man2,man3,man4,man5
                    if (mannschaft) {
                        return mannschaft.id;
                    }
                    return 0;
                };
                newDurchgang.push([
                    getMannschaftName(rule[0]),
                    getMannschaftName(rule[1])
                ]);
                newDurchgang.push([
                    getMannschaftName(rule[2]),
                    getMannschaftName(rule[3])
                ]);
                newDurchgang.push([
                    getMannschaftName(rule[4]),
                    getMannschaftName(rule[5])
                ]);
                if (isExistingRuleElement(rule, 6)) {
                    newDurchgang.push([
                        getMannschaftName(rule[6]),
                        getMannschaftName(rule[7])
                    ]);
                }
                console.log("done");
                setPaarungen((prevPaarungen) => [...prevPaarungen, newDurchgang]);
            }
        }
    }

    const handleDisziplinClick = (selectedCode) => {
        setShowGeneratedMatchups(false);
        setIsSaveSuccessful(false);
        setHasError(false);
        apiService.fetchLigenByDisziplin(selectedCode)
            .then(data => {
                setLigaList(data);
                setLoading(false);
            })
            .catch(error => console.error('Fehler beim Laden der Ligen:', error));

        apiService.fetchMannschaftenByDisziplin(selectedCode)
            .then(data => {
                setMannschaftenList(data);
                setLoading(false);
            })
            .catch(error => console.error('Fehler beim Laden der Liste:', error));
    };

    useEffect(() => {
        setLoading(true);
        ApiService.fetchDisziplin()
            .then(data => {
                setDisziplinList(data);
                setLoading(false);
            })
            .catch(error => console.error('Fehler beim Laden der Disziplinen:', error));
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    const handleSave = async () => {
        try {
            const response = await ApiService.createWettkampf(props, selectedLiga, paarungen);
            if (response) {
                setDisplayText(`Daten erfolgreich gespeichert`);
                setHasError(false);
                setIsSaveSuccessful(true);
            } else {
                setIsSaveSuccessful(false)
                setHasError(true);
                console.error('Fehler beim Speichern der Daten');
                setDisplayText('Fehler beim Speichern der Daten');
            }
        } catch
            (error) {
            setHasError(true);
            console.error('Fehler beim API-Aufruf', error);
            setDisplayText('Fehler beim API-Aufruf');
        }
    };

    return (
        <div className="element-content element-background">
            <p style={{color: 'red', alignItems: 'center'}}>!!! ADMINBEREICH => nur von Jochen zu benutzen !!!</p>
            <div className="grid-verwaltung-wettkampf">
                <div className="left-panel-wettkampf">
                    <NavbarDisziplin disziplinList={disziplinList} handleDisziplinClick={handleDisziplinClick}/>
                </div>
                <div className="mid-panel-wettkampf">
                    {ligaList.map((liga) => {
                            const filteredMannschaften = mannschaftenList
                                .filter((mannschaft) => mannschaft.liga !== null)
                                .filter((mannschaft) => mannschaft.liga.id === liga.id);

                            return (
                                <div key={liga.name}>
                                    <strong>{liga.name} -> </strong>
                                    <button onClick={() => generateSyncMatchups(liga.id)}> generieren</button>
                                    {filteredMannschaften.length === 6 && (
                                        <button onClick={() => generateAsyncMatchups(liga.id)}> async generieren</button>
                                    )}
                                    {filteredMannschaften.map((item, index) => (
                                        <div key={item.id}>
                                            {`${index + 1}. ${item.name}`}
                                        </div>
                                    ))}
                                </div>
                            )
                        }
                    )}
                </div>
                {showGeneratedMatchups && (
                    <div className="right-panel-wettkampf">
                        {paarungen.map((durchgang, index) => (
                            <div key={`Wettkampf${index}`}>
                                <h3>{index + 1}. Wettkampf</h3>
                                {durchgang.map((pair, pairIndex) => (
                                    <div key={`Paar${pairIndex}`}>
                                        <div className="paarungen">
                                            {mannschaftenList.find((mannschaft) => mannschaft.id === pair[0]).name}
                                            :
                                            {pair[1] !== 0 ?
                                                mannschaftenList.find((mannschaft) => mannschaft.id === pair[1]).name
                                                : "ohne Gegner"
                                            }</div>
                                    </div>
                                ))}
                            </div>
                        ))}
                        <button
                            className={`saveButton ${isSaveSuccessful ? 'success' : ''} ${hasError ? 'error' : ''}`}
                            onClick={handleSave}>Speichern
                        </button>
                        <div
                            className={`hint ${isSaveSuccessful ? 'success' : ''} ${hasError ? 'error' : ''}`}>{displayText} </div>
                    </div>
                )}
                {!showGeneratedMatchups && ligaList.length > 0 && (
                    <div className="right-panel-wettkampf">
                        <p> Wähle, für welche Liga Wettkämpfe generiert werden sollen</p>
                    </div>
                )}
                {!showGeneratedMatchups && ligaList.length === 0 && (
                    <div className="right-panel-wettkampf">
                        <p> Wähle, eine Disziplin aus</p>
                    </div>
                )}

            </div>
        </div>
    )
        ;
}

export default VerwaltungWettkampf;

