import React from 'react';

function NavbarAltersklassen({onLogin, handleAltersklassenClick, altersklassenList, selectedAltersklasse}) {
    return (
        <nav className="navbaraltersklassen">
            {altersklassenList.map((altKl) => (
                <button key={altKl.code}
                        onClick={() => handleAltersklassenClick(altKl.code)}
                className={altKl.code === selectedAltersklasse ? 'active' : ''}>
                    {altKl.name}
                </button>
            ))}
        </nav>
    );
}

export default NavbarAltersklassen;
