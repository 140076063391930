import React, {useEffect, useState} from 'react';
import '../Setzliste.css';
import NavbarLiga from "../components/NavbarLiga";
import ApiService from "../services/ApiService";
import Setzliste from "../Setzliste";

function Ab10mSetzliste() {
    const [loading, setLoading] = useState(false);
    const [ligaList, setLigaList] = useState([]);

    const [selectedLiga, setSelectedLiga] = useState(null);
    const [mannschaftenList, setMannschaftenList] = useState(null);
    const [collectedErgebnisData, setCollectedErgebnisData] = useState([]);

    const handleLigaClick = (selectedLiga) => {
        setSelectedLiga(selectedLiga);

        ApiService.fetchMannschaftenByLiga(2024, selectedLiga.id)
            .then(data => {
                setMannschaftenList(data);
            })
            .catch(error => console.error('Fehler beim Laden der Daten für die rechte Spalte:', error));
    };

    useEffect(() => {
        setLoading(true);
        ApiService.fetchLigenByDisziplin("AB10m")
            .then(data => {
                setLigaList(data);
                setLoading(false);
            })
            .catch(error => console.error('Fehler beim Laden der Ligen:', error));
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <div className="element-content element-background">
            <div className="grid-show-liste">
                <div className="top-panel-liste">
                    <NavbarLiga ligaList={ligaList} handleLigaClick={handleLigaClick}></NavbarLiga>
                </div>
                {mannschaftenList && (
                    <ul className="right-panel-setzliste">
                        {mannschaftenList.map((current, index) => (
                            <li key={current.id} className={index % 2 === 0 ? 'even' : 'odd'}>
                                    <label> {current.name} </label>
                                    <Setzliste mannschaftId={current.id}/>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
}

export default Ab10mSetzliste;
