import React from 'react';

function Startseite() {
    return (
        <div className="element-content element-background">
            <h2>Ligaverwaltung der Deutschen Armbrustliga</h2>
            <p>
                Anzeigen und Verwalten des Ligabetriebs der Deutschen Armbrustliga.
            </p>
            <p>
                Die Seite ist aktuell in Entwicklung und wird ständig erweitert.
            </p>
        </div>
    );
}

export default Startseite;
