import React, {useEffect, useState} from 'react';
import '../Setzliste.css';
import ApiService from "../services/ApiService";
import NavbarAltersklassen from "../components/NavbarAltersklassen";

function Ab10mEinzelwertung() {
    const [loading, setLoading] = useState(false);
    const [altersklassenList, setAltersklassenList] = useState([]);

    const [selectedAltersklasse, setSelectedAltersklasse] = useState(null);
    const [einzelwertungList, setEinzelwertungList] = useState(null);

    const handleKlasseClick = (selectedAltersklasse) => {
        setSelectedAltersklasse(selectedAltersklasse);
        ApiService.fetchEinzelwertung("AB10m", 2024, selectedAltersklasse)
            .then(data => {
                setEinzelwertungList(data);
            })
            .catch(error => console.error('Fehler beim Laden der Daten für die rechte Spalte:', error));
    };

    useEffect(() => {
        setLoading(true);
        ApiService.fetchAltersklassen("AB10m")
            .then(data => {
                setAltersklassenList(data);
                setLoading(false);
            })
            .catch(error => console.error('Fehler beim Laden der Altersklassen:', error));
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <div className="element-content element-background">
            <div className="grid-show-liste">
                <div className="top-panel-liste">
                    <NavbarAltersklassen altersklassenList={altersklassenList}
                                         handleAltersklassenClick={handleKlasseClick}
                                         selectedAltersklasse={selectedAltersklasse}>
                    </NavbarAltersklassen>
                </div>
                <div className="body-panel-liste">
                    {einzelwertungList && (
                        <table className="liste-tabelle">
                            <thead>
                            <tr>
                                <th>Platz</th>
                                <th>Nachname</th>
                                <th>Vorname</th>
                                <th>Verein</th>
                                <th>DG 1</th>
                                <th>DG 2</th>
                                <th>DG 3</th>
                                <th>DG 4</th>
                                <th>DG 5</th>
                                <th>DG 6</th>
                                <th>Durchschnitt</th>
                            </tr>
                            </thead>
                            <tbody>
                            {einzelwertungList.map((entry, index) => (
                                <tr key={entry.id} className={index % 2 === 0 ? 'even' : 'odd'}>
                                    <td>{index + 1}</td>
                                    <td>{entry.vorname}</td>
                                    <td>{entry.nachname}</td>
                                    <td>{entry.verein}</td>
                                    <td>{entry.durchgang1}</td>
                                    <td>{entry.durchgang2}</td>
                                    <td>{entry.durchgang3}</td>
                                    <td>{entry.durchgang4}</td>
                                    <td>{entry.durchgang5}</td>
                                    <td>{entry.durchgang6}</td>
                                    <td>{entry.avg}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Ab10mEinzelwertung;
