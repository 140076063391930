import React from 'react';
import ApiService from "../services/ApiService";

function Bau(props) {
    const handled = async () => {
        try {
            const response = await ApiService.dAction(
                props
            )
            if (response) {
                console.log('done');
            }
            if (!response) {
                console.error('failed');
            }
        } catch (error) {
            console.error('Fehler beim API-Aufruf', error);
        }
    }
    return (
        <div className="element-content element-background">
            <div>bitte wählt ein Untermenu aus!</div>

            <div>diese Seite ist für manuelle Arbeiten an der produktiven Seite</div>
            <div>Daher hier bitte unbedingt: nichts drücken!!!</div>

            <button
                className={`dButton`}
                onClick={handled}>oh oh
            </button>
        </div>
    );
}

export default Bau;
