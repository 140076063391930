import {Link, Route, Routes} from "react-router-dom";
import React from "react";
import './Navbar.css';
import Startseite from "../Startseite";
import Ab10m from "../pages/Ab10m";
import AB10mk from "../pages/AB10mk";
import Ab30m from "../pages/Ab30m";
import Verwaltung from "../pages/Verwaltung";
import Login from "../Login";
import Ab10ma from "../pages/Ab10ma";
import Stammdaten from "../pages/Stammdaten";
import Saisondaten from "./Saisondaten";
import VerwaltungWettkampf from "../pages/VerwaltungWettkampf";
import Ergebnisse from "./Ergebnisse";
import Mannschaftsmeldung from "../pages/Mannschaftsmeldung";
import Ab10mSetzliste from "../pages/Ab10mSetzliste";
import Ab10mEinzelwertung from "../pages/Ab10mEinzelwertung";
import Bau from "../pages/bau";
import Ab10mkEinzelwertung from "../pages/Ab10mkEinzelwertung";
import Ab10mAEinzelwertung from "../pages/Ab10mAEinzelwertung";
import Ab30mEinzelwertung from "../pages/Ab30mEinzelwertung";

export function Navbar(props) {
    return <main>
        <nav className="navbar">
            <ul>
                <li><Link to="/Startseite">Startseite</Link></li>
                <li>
                    <Link to="/Ab10m">Armbrust 10m</Link>
                    <ul>
                        <li><Link to="SetzlistenAB10m">Setzlisten</Link></li>
                    </ul>
                    <ul>
                        <li><Link to="EinzelwertungAB10m">Einzelwertung</Link></li>
                    </ul>
                </li>
                <li>
                    <Link to="/AB10mk">Armbrust 10m kniend</Link>
                    <ul>
                        <li><Link to="SetzlistenAB10mk">Setzlisten</Link></li>
                    </ul>
                    <ul>
                        <li><Link to="EinzelwertungAB10mk">Einzelwertung</Link></li>
                    </ul>
                </li>
                <li>
                    <Link to="/Ab10ma">Armbrust 10m Auflage</Link>
                    <ul>
                        <li><Link to="SetzlistenAB10ma">Setzlisten</Link></li>
                    </ul>
                    <ul>
                        <li><Link to="EinzelwertungAB10ma">Einzelwertung</Link></li>
                    </ul>
                </li>
                <li>
                    <Link to="/Ab30m">Armbrust 30m</Link>
                    <ul>
                        <li><Link to="SetzlistenAB30m">Setzlisten</Link></li>
                    </ul>
                    <ul>
                        <li><Link to="EinzelwertungAB30m">Einzelwertung</Link></li>
                    </ul>
                </li>
                {props.loggedIn && (
                    <li>
                        <Link to="/Verwaltung">Verwaltung</Link>
                        <ul>
                            <li><Link to="/Verwaltung/Stammdaten">Stammdaten</Link></li>
                            <li><Link to="/Verwaltung/Saisondaten">Saisondaten</Link></li>
                            <li><Link to="/Verwaltung/Wettkampf">Wettkämpfe</Link></li>
                        </ul>
                    </li>
                )}
                {props.loggedIn && (
                    <li>
                        <Link to="/Konfiguration">Konfiguration</Link>
                        <ul>
                            <li><Link to="/Konfiguration/Ergebnisse">Ergebnisse</Link></li>
                            <li><Link to="/Konfiguration/Mannschaftsmeldung">Mannschaftsmeldung</Link></li>
                        </ul>
                    </li>
                )}
            </ul>
        </nav>
        <Routes>
            <Route path="/Startseite" element={<Startseite/>}/>
            <Route path="/Ab10m" element={<Ab10m/>}/>
            <Route path="SetzlistenAB10m" element={<Ab10mSetzliste/>}/>
            <Route path="EinzelwertungAB10m" element={<Ab10mEinzelwertung/>}/>

            <Route path="/AB10mk" element={<AB10mk/>}/>
            <Route path="SetzlistenAB10mk" element={<Ab30m/>}/>
            <Route path="EinzelwertungAB10mk" element={<Ab10mkEinzelwertung/>}/>

            <Route path="/AB10ma" element={<Ab10ma/>}/>
            <Route path="SetzlistenAB10ma" element={<Ab30m/>}/>
            <Route path="EinzelwertungAB10ma" element={<Ab10mAEinzelwertung/>}/>

            <Route path="/Ab30m" element={<Ab30m/>}/>
            <Route path="SetzlistenAB30m" element={<Ab30m/>}/>
            <Route path="EinzelwertungAB30m" element={<Ab30mEinzelwertung/>}/>


            <Route path="/Ab10m" element={<Ab10ma/>}>
                <Route path="unterelement2.1" element={<Ab10ma/>}/>
                <Route path="unterelement2.2" element={<Ab10ma/>}/>
            </Route>

            {props.loggedIn && (
                <Route path="/Verwaltung" element={<Verwaltung basicAuth={props.basicAuth}/>}/>
            )}
            {props.loggedIn && (
                <Route path="/Verwaltung/Stammdaten" element={<Stammdaten basicAuth={props.basicAuth}/>}/>
            )}
            {props.loggedIn && (
                <Route path="/Verwaltung/Saisondaten" element={<Saisondaten basicAuth={props.basicAuth}/>}/>
            )}
            {props.loggedIn && (
                <Route path="/Verwaltung/Wettkampf" element={<VerwaltungWettkampf basicAuth={props.basicAuth}/>}/>
            )}
            {props.loggedIn && (
                <Route path="/Konfiguration" element={<Bau basicAuth={props.basicAuth}/>}/>
            )}
            {props.loggedIn && (
                <Route path="/Konfiguration/Ergebnisse" element={<Ergebnisse basicAuth={props.basicAuth}/>}/>
            )}
            {props.loggedIn && (
                <Route path="/Konfiguration/Mannschaftsmeldung"
                       element={<Mannschaftsmeldung basicAuth={props.basicAuth}/>}/>
            )}
            <Route path="/login" element={<Login onLogin={props.onLogin}/>}/>
        </Routes>
    </main>;
}