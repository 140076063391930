class ApiService {
    // APIPROD
    //static baseUrl = 'http://localhost:8080';
    static baseUrl = 'https://api.armbrust.agrib-technology.de';
    //  static isAuthenticated = false; // Beispiel für den Authentifizierungsstatus

    static setAuthenticationStatus(status) {
        this.isAuthenticated = status;
    }

    static async fetchListen(request) {
        const url = `${this.baseUrl}/info/${request}`;
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.json();
    }

    static async fetchDisziplin() {
        const url = `${this.baseUrl}/info/disziplin`;
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.json();
    }

    static async fetchAltersklassen(disziplin) {
        const url = `${this.baseUrl}/info/altersklassen/disziplin/${disziplin}`;
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.json();
    }

    static async fetchLigenByDisziplin(ligaCode) {
        const url = `${this.baseUrl}/info/liga/${ligaCode}`;
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.json();
    }

    static async fetchVerein() {
        const url = `${this.baseUrl}/info/verein`;
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.json();
    }

    static async fetchVereinByDisziplin(selectedCode) {
        const url = `${this.baseUrl}/info/disziplin/${selectedCode}/verein`;
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.json();
    }

    static async fetchMannschaftenByDisziplin(disziplinCode) {
        const url = `${this.baseUrl}/info/disziplin/${disziplinCode}/mannschaften`;
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.json();
    }

    static async fetchMannschaftenByLiga(jahr, ligaId) {
        const url = `${this.baseUrl}/info/liga/${ligaId}/mannschaften`;
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.json();
    }


    static async fetchMannschaftenByDisziplinAndVerein(selectedDisziplinId, selectedVereinId) {
        const url = `${this.baseUrl}/info/disziplin/${selectedDisziplinId}/verein/${selectedVereinId}/mannschaften`;
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.json();
    }

    static async fetchPersonsByVerein(basicAuth, selectedVereinId) {
        const url = `${this.baseUrl}/info/verein/${selectedVereinId}/personen`;
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                "Authorization": "Basic " + basicAuth,
                'Content-Type': 'application/json'
            }
        });
        return response.json();
    }

    static async fetchPersonsByMannschaft(props, selectedMannschaftId) {
        const url = `${this.baseUrl}/info/person/${selectedMannschaftId}`;
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                "Authorization": "Basic " + props.basicAuth,
                'Content-Type': 'application/json'
            }
        });
        return response.json();
    }

    static async fetchPaarungen(year, ligaId) {
        const url = `${this.baseUrl}/info/paarungen/${year}/${ligaId}`;
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.json();
    }

    static async fetchTable(year, ligaId) {
        const url = `${this.baseUrl}/info/table/${year}/${ligaId}`;
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.json();
    }

    static async fetchResults(year, ligaId) {
        const url = `${this.baseUrl}/info/results/${year}/${ligaId}`;
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.json();
    }

    static async fetchEinzelwertung(disziplinCode, year, altKlId) {
        const url = `${this.baseUrl}/info/einzelwertung/disziplin/${disziplinCode}/jahr/${year}/klasse/${altKlId}`;
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.json();
    }

    static async fetchSetzliste(year, mannschaftId) {
        const url = `${this.baseUrl}/info/setzliste/${year}/${mannschaftId}`;
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.json();
    }

    static async createSaisondaten(props, mannschaftenList) {
        const url = `${this.baseUrl}/api/update/mannschaften`;
        const response = await fetch(url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                "Authorization": "Basic " + props.basicAuth,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                mannschaftenList
            }),
        });
        return !!(response.ok);
    }

    static async createVerein(props, verein, verband) {
        const url = `${this.baseUrl}/api/create/verein`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                "Authorization": "Basic " + props.basicAuth,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                verein,
                verband
            }),
        });
        return !!(response.ok);
    }

    static async createPerson(props, vorname, nachname, geburtsjahr, geschlecht,  vereinId) {
        const url = `${this.baseUrl}/api/create/person`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                "Authorization": "Basic " + props.basicAuth,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                vorname,
                nachname,
                geburtsjahr,
                geschlecht,
                vereinId
            }),
        });
        return !!(response.ok);
    }

    static async createMannschaft(props, disziplinId, vereinId, mannschaft) {
        const url = `${this.baseUrl}/api/create/mannschaft`;
        const response = await fetch(url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                "Authorization": "Basic " + props.basicAuth,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                disziplinId,
                vereinId,
                mannschaft
            }),
        });
        return !!(response.ok);
    }

    static async createMannschaftsmeldung(props, personId, disziplinId, mannschaftId) {
        const url = `${this.baseUrl}/api/create/mannschaftmeldung/${personId}`;
        const response = await fetch(url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                "Authorization": "Basic " + props.basicAuth,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                disziplinId: disziplinId,
                mannschaftId: mannschaftId
            }),
        });
        return !!(response.ok);
    }

    static async createWettkampf(props, ligaId, paarungen) {
        const url = `${this.baseUrl}/api/create/wettkampf`;
        const response = await fetch(url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                "Authorization": "Basic " + props.basicAuth,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                liga: ligaId,
                paarungen: paarungen.map(durchgang => durchgang.map(pair => ({id_heim: pair[0], id_fern: pair[1]})))
            }),
        });
        return !!(response.ok);
    }

    static async createErgebnisse(props, ergebnisDto) {
        const url = `${this.baseUrl}/api/create/ergebnisse`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': 'Basic ' + props.basicAuth,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(ergebnisDto),
        });
        return !!(response.ok);
    }

    static async fetchLogin(basicAuth) {
        const url = `${this.baseUrl}/api/login`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                "Authorization": "Basic " + basicAuth
            }
        });
        return response.json();
    }

    static async dAction(props) {
        const url = `${this.baseUrl}/api/update/d`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                "Authorization": "Basic " + props.basicAuth,
                'Content-Type': 'application/json'
            }
        });
        return response.json();
    }
}

export default ApiService;
